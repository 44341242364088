<template>
  <div class="container">
    <div class="tl spread">
      <div style="margin: 0 20px">
        <input
            v-model="gtin"
            placeholder="条形码/二维码值（查询时必填）"
            style="width: 240px;background-color: transparent"
            class="input_box"
        />
        <input
            v-model="batchNumber"
            placeholder="批次（非必填）"
            style="width: 200px;background-color: transparent"
            class="input_box"
        />
        <span class="button"  @click="handleSearch">查询</span>
        <span class="button" @click="handleRest">重置</span>
      </div>
      <div class="spread">
        <ve-bmap
            v-if="mapShow"
            ref="mapRef"
            height="100%"
            :settings="chartSettings"
            :series="chartSeries"
            :tooltip="chartTooltip"
            :events="chartEvents"
        >
          <div
              class="chain-block-box"
              v-show="companyList && companyList.length > 0"
          >
            Hash值区块链验证:
            <span v-if="!hashFlag" class="hash-value" @click="verifyHash">
            <i class="el-icon-thumb"></i>
            {{ hashVal }}
          </span>
            <span v-else class="hash-result" @click="verifyHash">数据无篡改</span>
          </div>
<!--          <div class="default-desc col-fff tc">默认显示最新50条流向记录</div>-->
        </ve-bmap>
      </div>
    </div>
<!--    <el-dialog-->
<!--            title="信息"-->
<!--            :visible.sync="dialogVisible"-->
<!--            width="30%"-->
<!--            :show-close="false"-->
<!--            >-->
<!--        <div class="p50">-->
<!--          <div class="df">-->
<!--            <div class="flex-8 tc">-->
<!--              <h2>追溯环节</h2>-->
<!--            </div>-->
<!--            <div class="flex-16 tl">-->
<!--              <h2>{{dialogData.name}}</h2>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="df">-->
<!--            <div class="flex-8 tc">-->
<!--              <h2>追溯可视化</h2>-->
<!--            </div>-->
<!--            <div class="flex-16 tl">-->
<!--              <el-image-->
<!--                      v-for="(item,index) in srcList"-->
<!--                      :key="index"-->
<!--                      style="width: 200px; height: 200px"-->
<!--                      :src="item"-->
<!--                      fit="cover"-->
<!--                      :preview-src-list="srcList"-->
<!--                      class="mr10"-->
<!--              ></el-image>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="df">-->
<!--            <div class="flex-8 tc">-->
<!--              <h2>追溯记录</h2>-->
<!--            </div>-->
<!--            <div class="flex-16 tl">-->
<!--              <h2>{{dialogData.remark}}</h2>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="df">-->
<!--            <div class="flex-8 tc">-->
<!--              <h2>创建时间</h2>-->
<!--            </div>-->
<!--            <div class="flex-16 tl">-->
<!--              <h2>{{dialogData.createdTime ? dialogData.createdTime.replace('T',' ') : ''}}</h2>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="df" v-show="dialogData.isHasDevice">-->
<!--            <div class="flex-8 tc">-->
<!--              <h2>传感器监测</h2>-->
<!--            </div>-->
<!--            <div class="flex-16 tl">-->
<!--              <el-button type="primary" round size="mini" @click="lookInfo()">查看传感器信息</el-button>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div v-if="showInfo" style="height: 450px; width: 100%;">-->
<!--            <div  class="mt20" v-for="(tItem,tIndex) in temperatures"  :key="tIndex" style="width: 100%">-->
<!--&lt;!&ndash;              <van-divider>{{tItem.deviceName}}</van-divider>&ndash;&gt;-->
<!--              <div :id="'temperatureInfo'+tIndex" style="height:380px;width: 100%">-->
<!--&lt;!&ndash;                <van-empty :description="info" />&ndash;&gt;-->
<!--                <el-empty :description="info"></el-empty>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      <span slot="footer" class="dialog-footer">-->
<!--        <el-button type="primary" @click="dialogVisible = false,showInfo = false">确 定</el-button>-->
<!--      </span>-->
<!--    </el-dialog>-->

    <div class="t-dialog" id="fade-box" v-show="dialogVisible">
      <div class="p50 record-bg">
        <div class="tf-title">
          信息
        </div>
        <div class="df pr">
          <div class="flex-8 tc col-fff f20 fw-b">
            <h1>追溯环节</h1>
          </div>
          <div  class="flex-16 tl col-fff">
            <h1>{{dialogData.name}}</h1>
          </div>
        </div>
        <div class="df pr">
          <div class="flex-8 tc col-fff f20 fw-b">
            <h1>追溯可视化</h1>
          </div>
          <div class="flex-16 tl">
            <el-image
                    v-for="(item,index) in srcList"
                    :key="index"
                    style="width: 200px; height: 200px"
                    :src="item"
                    fit="cover"
                    :preview-src-list="srcList"
                    class="mr10"
            ></el-image>
          </div>
        </div>
        <div class="df pr">
          <div class="flex-8 tc col-fff f20 fw-b">
            <h1>追溯记录</h1>
          </div>
          <div  class="flex-16 tl col-fff">
            <h1>{{dialogData.remark}}</h1>
          </div>
        </div>
        <div class="df pr">
          <div class="flex-8 tc col-fff f20 fw-b">
            <h1>创建时间</h1>
          </div>
          <div  class="flex-16 tl col-fff">
            <h2>{{dialogData.createdTime ? dialogData.createdTime.replace('T',' ') : ''}}</h2>
          </div>
        </div>
        <div class="df pr" v-show="dialogData.isHasDevice">
          <div class="flex-8 tc col-fff f20 fw-b">
            <h1>传感器监测</h1>
          </div>
          <div class="flex-16 tl">
            <div class="button" @click="lookInfo()">查看传感器信息</div>
          </div>
        </div>
        <div v-if="showInfo" style="height: 450px; width: 100%;">
          <div  class="mt20" v-for="(tItem,tIndex) in temperatures"  :key="tIndex" style="width: 100%">
            <!--              <van-divider>{{tItem.deviceName}}</van-divider>-->
            <div :id="'temperatureInfo'+tIndex" style="height:380px;width: 100%">
              <!--                <van-empty :description="info" />-->
              <el-empty :description="info"></el-empty>
            </div>
          </div>
        </div>

<!--          <div  class="df pr">-->
<!--            <div v-for="(vidoe,vidoeIndex) in JSON.parse(item.content)" :key="vidoeIndex">-->
<!--              <video Controls :poster="vidoe + '?x-oss-process=video/snapshot,t_1,m_fast'" class="video-js t-border mb10" style="height: 260px;width: 100%;margin-bottom: 5px">-->
<!--                <source :src="vidoe" type="video/mp4"/>-->
<!--              </video>-->
<!--            </div>-->
<!--          </div>-->
<!--     -->
        <div class="t-close" @click="dialogVisible = false">
          ×
        </div>

      </div>

    </div>
  </div>

</template>

<script>
import { createHash } from "@/util/util";
import { mapConfig } from "./custom_map_config.js";
import {deepClone} from "../../../util/util";
var echarts = require('echarts/lib/echarts');
// 引入柱状图
require('echarts/lib/chart/line');
require('echarts/lib/chart/pie');
// 引入提示框和标题组件
require('echarts/lib/component/tooltip');
require('echarts/lib/component/legend');
require('echarts/lib/component/title');
let option;
export default {
  data() {
    this.chartSettings = {
      key: "Ns87bUghsWzPirriDs7uPGPYQOlGUKYQ",
      bmap: {
        center: [120, 30],
        zoom: 5,
        roam: true,
        enableMapClick: false,
        mapStyleV2: { styleJson: mapConfig },
      },
    };
    this.chartTooltip = {
      show: true,
      formatter: function (params, ticket, callback) {

        if (params.seriesType === "effectScatter") {
          //console.log("params",params)
          return `${params.marker}追溯环节:${params.value[2]}<br>
                  ${params.marker}商品名称:${params.value[3].goodsName}
`;
        } else if (params.seriesType === "lines") {
          return `${params.data.fromName} > ${params.data.toName}<br>
                    转运时长: ${params.data.time}
           `;
        }
      },
    };
    const _this = this;
    this.chartEvents = {
      click: (v) => {

        if (v.seriesType === "effectScatter") {
          //console.log("点击坐标点",v)
          this.dialogData = v.data[3]
          this.srcList = JSON.parse(v.data[3].imgList).map(m => m.url)
          this.dialogVisible = true
        }
      },
      finished: (_) => {},
    };
    return {
      gtin: "",
      batchNumber: "",
      mapShow: false,
      companyList: [],
      chartSeries: [],
      hashFlag: false,
      hashVal: createHash(30),
      dialogVisible: false,
      dialogData: {
        name: '',
        remark: '',
        createdTime: '',
        isHasDevice: false
      },
      srcList: [],
      showInfo:false,
      temperatures: null,
      info: ''
    };
  },
  computed: {},
  watch:{
    dialogVisible(val){
      if(!val){
        this.showInfo = val
      }
    }
  },
  created() {

  },
  mounted() {

    this.initMap();
    this.getDate();
  },
  methods: {
    lookInfo(){
      let item = this.dialogData
      this.$extraApi.get('/v1/wx/fullChainTrace/temperatureInfo',{id:item.id}).then(res=>{
        const { code, data } = res
        if(code == 200){

          this.temperatures = data;
          this.temperatures.forEach((f,fIndex)=>{
            if(f.temperatures&&f.temperatures.length>0){
              option.xAxis.data = f.temperatures.map(m=>{
                return m.x;
              });
              option.yAxis[0].name = f.unit
              option.series[0].name = `${f.name}(${f.unit})`
              option.legend.data[0] = `${f.name}(${f.unit})`
              option.dataZoom[0].startValue = option.xAxis.data[option.xAxis.data.length>8?option.xAxis.data.length-8:0];

              option.dataZoom[0].endValue = option.xAxis.data[option.xAxis.data.length-1];
              option.series[0].data = f.temperatures.map(m=>{
                return m.y;
              });
              let o = deepClone(option)
              setTimeout(function () {
                let myChart = echarts.init(document.getElementById("temperatureInfo"+fIndex));
                //console.log("mychart",myChart)
                myChart.setOption(o);
              },500)
              this.info = '';
            }else{
              this.info = "该传感器暂无数据"
            }
          })
          this.showInfo = true;
        }
      })

    },
    getDate () {
      option=null;
      option =  {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
          position: function (pt) {
            return [pt[0], "25%"];
          },
        },
        legend: {
          data: [""],
        },
        grid: {
          top: 50,
          bottom: 30,
          left: 40,
          right: 40,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [],
          axisLabel:{
            rotate:"45"
          }
        },
        yAxis: [
          { name: '',
            show:true,
            type: "value",
            interval: 5,
            max:50}
        ],
        dataZoom: [
          {
            type:"inside",
            zoomLock: true,
            startValue: "",
            endValue:"",
            moveHandleSize:9,
            zoomOnMouseWheel:true,
            rangeMode: ["percent", "percent"],
          },
        ],
        series: [
          {
            name: '',
            type: "line",
            itemStyle: {
              normal: { color: '#ffffff', label: { show: true,position:"bottom" } },
            },
            smooth: true,
            yAxisIndex:0,
            legendHoverLink:true,
            data: [],
          }
        ],
      };
    },
    initMap(params) {
      const loading = this.$loading({
        target:document.querySelector('.container'),
        lock: true,
        text: "数据加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.mapShow = false;
      this.chartSeries = [];
      this.$extraApi
        .get(`/v1/pc/flow/fullFlow`,{ opt: 2, gtin: this.gtin, batchNumber: this.batchNumber })
        .then((response) => {
          let res = response.data;
          //console.log("res",res)
          if (res.length > 0) {
            let convertData = this.convertData(res);
            let arr = [];
            let planePath =
              "path://M.6,1318.313v-89.254l-319.9-221.799l0.073-208.063c0.521-84.662-26.629-121.796-63.961-121.491c-37.332-0.305-64.482,36.829-63.961,121.491l0.073,208.063l-319.9,221.799v89.254l330.343-157.288l12.238,241.308l-134.449,92.931l0.531,42.034l175.125-42.917l175.125,42.917l0.531-42.034l-134.449-92.931l12.238-241.308L1705";
            convertData.forEach((item, index) => {
              arr.push(
                {
                  type: "effectScatter",
                  coordinateSystem: "bmap",
                  zlevel: 2,
                  rippleEffect: {
                    //涟漪特效
                    period: 4, //动画时间，值越小速度越快
                    brushType: "stroke", //波纹绘制方式 stroke, fill
                    scale: 4, //波纹圆环最大限制，值越大波纹越大
                  },
                  symbol: "circle",
                  itemStyle: {
                    normal: {
                      color: "#3ed4ff",
                    },
                  },
                  data: item.fromData,
                },
                {
                  type: "effectScatter",
                  coordinateSystem: "bmap",
                  zlevel: 2,
                  rippleEffect: {
                    //涟漪特效
                    period: 4, //动画时间，值越小速度越快
                    brushType: "stroke", //波纹绘制方式 stroke, fill
                    scale: 4, //波纹圆环最大限制，值越大波纹越大
                  },
                  symbol: "circle",
                  itemStyle: {
                    normal: {
                      color: "#3ed4ff",
                    },
                  },
                  data: item.toData,
                },
                {
                  type: "lines",
                  coordinateSystem: "bmap",
                  zlevel: 1,
                  effect: {
                    show: true,
                    period: 6,
                    trailLength: 0.7,
                    color: "#fff",
                    symbolSize: 3,
                  },
                  lineStyle: {
                    normal: {
                      color: "#3ed4ff",
                      width: 0,
                      curveness: 0.2,
                    },
                  },
                  data: item.lineData,
                },
                {
                  type: "lines",
                  coordinateSystem: "bmap",
                  zlevel: 2,
                  effect: {
                    show: true,
                    period: 6,
                    trailLength: 0,
                    symbol: planePath,
                    symbolSize: 15,
                  },
                  lineStyle: {
                    normal: {
                      color: "#3ed4ff",
                      width: 1,
                      opacity: 0.4,
                      curveness: 0.2,
                    },
                  },
                  data: item.lineData,
                }
                // {
                //   type: "lines",
                //   coordinateSystem: "bmap",
                //   zlevel: 1,
                //   effect: {
                //     show: true,
                //     period: 6, //箭头指向速度，值越小速度越快
                //     trailLength: 0.7, //特效尾迹长度[0,1]值越大，尾迹越长重
                //     // symbol: "arrow", //箭头图标
                //     color: "#fff",
                //     symbolSize: 5, //图标大小
                //   },
                //   lineStyle: {
                //     normal: {
                //       color: "#3ed4ff",
                //       width: 1, //尾迹线条宽度
                //       // opacity: 1, //尾迹线条透明度
                //       curveness: 0.3, //尾迹线条曲直度
                //     },
                //   },
                //   data: item.lineData,
                // }
              );
            });
            this.chartSeries = arr;
          }
          this.mapShow = true;
          this.getGeo();
        });
        loading.close();
    },
    handleSearch() {
      if (this.gtin) {
        this.initMap();
      } else {
        this.$message({
          showClose: true,
          message: "条形码/二维码值为必填项",
          type: "warning",
        });
      }
    },
    handleRest() {
      this.gtin = "";
      this.batchNumber = "";
      this.initMap();
    },
    afterSet: function (echarts) {
      var bmap = echarts.getModel().getComponent("bmap").getBMap();
      bmap.addControl(new window.BMap.MapTypeControl());
    },
    convertData(data) {
      let result = [];
      let companyList = [];
      let afterData = [];
      let tempArr = [];
      // for (let i = 0; i < data.length; i++) {
      //   if (
      //     tempArr.findIndex(
      //       (t) => t.fromCompanyId == data[i].from.company_id
      //     ) === -1 &&
      //     tempArr.findIndex((t) => t.toCompanyId == data[i].to.company_id) ===
      //       -1
      //   ) {
      //     afterData.push({
      //       from: data[i].from,
      //       to: data[i].to,
      //       info: {
      //         goods_name: data[i].info.goods_name,
      //         batches: [data[i].info.batch],
      //       },
      //     });
      //     tempArr.push({
      //       fromCompanyId: data[i].from.company_id,
      //       toCompanyId: data[i].to.company_id,
      //     });
      //   } else {
      //     for (let j = 0; j < afterData.length; j++) {
      //       if (
      //         afterData[j].from.company_id == data[i].from.company_id &&
      //         afterData[j].to.company_id == data[i].to.company_id
      //       ) {
      //         afterData[j].info.batches.push(data[i].info.batch);
      //         break;
      //       }
      //     }
      //   }
      // }
      // data = afterData;
      for (let i = 0; i < data.length; i++) {
        let fromData = [];
        let toData = [];
        let lineData = [];
        let fromLong = data[i].from.longitude;
        let fromLat = data[i].from.latitude;
        let toLong = data[i].to.longitude;
        let toLat = data[i].to.latitude;
        fromData = [[fromLong, fromLat, data[i].from.name,data[i].from]];
        companyList.push({
          longitude: fromLong,
          latitude: fromLat,
        });
        toData = [[toLong, toLat, data[i].to.name,data[i].to]];
        let time = this.getInervalHour(data[i].from.createdTime,data[i].to.createdTime)
        //console.log("time",time)
        lineData = [
          {
            fromName: data[i].from.name,
            toName: data[i].to.name,
            goodsName: data[i].goodsInfo.goodsName,
            batch: data[i].goodsInfo.batchNumber,
            coords: [
              [fromLong, fromLat],
              [toLong, toLat],
            ],
            time: time
          },
        ];
        companyList.push({
          longitude: toLong,
          latitude: toLat,
        });
        result.push({ fromData, toData, lineData });
      }
      // for (let i = 0; i < data.length; i++) {
      //   let fromData = [];
      //   let toData = [];
      //   let lineData = [];
      //   fromData = [
      //     [
      //       data[i].from.company_longitude,
      //       data[i].from.company_latitude,
      //       data[i].from.company_name,
      //     ],
      //   ];
      //   companyList.push({
      //     longitude: data[i].from.company_longitude,
      //     latitude: data[i].from.company_latitude,
      //   });
      //   toData = [
      //     [
      //       data[i].to.company_longitude,
      //       data[i].to.company_latitude,
      //       data[i].to.company_name,
      //     ],
      //   ];
      //   lineData = [
      //     {
      //       fromName: data[i].from.company_name,
      //       toName: data[i].to.company_name,
      //       goodsName: data[i].info.goods_name,
      //       batch: data[i].info.batch,
      //       coords: [
      //         [data[i].from.company_longitude, data[i].from.company_latitude],
      //         [data[i].to.company_longitude, data[i].to.company_latitude],
      //       ],
      //     },
      //   ];
      //   companyList.push({
      //     longitude: data[i].to.company_longitude,
      //     latitude: data[i].to.company_latitude,
      //   });
      //   result.push({ fromData, toData, lineData });
      // }
      this.companyList = companyList;
      return result;
    },
    getInervalHour(startDate, endDate) {
      var ms = new Date(endDate) - new Date(startDate)
      if (ms < 0) return 0;
      return Math.floor(ms/1000/60/60) + '时' + Math.floor(ms/1000/60/60/60) + '分';
    },
    getGeo() {
      if (this.companyList.length > 0) {
        setTimeout((_) => {
          let pointArr = [];
          this.companyList.forEach((item) => {
            pointArr.push(new window.BMap.Point(item.longitude, item.latitude));
          });
          let echarts = this.$refs.mapRef.echarts;
          let map = echarts.getModel().getComponent("bmap").getBMap();
          let view = map.getViewport(eval(pointArr));
          let mapZoom = view.zoom > 2 ? view.zoom - 1 : view.zoom;
          let centerPoint = view.center;
          map.centerAndZoom(centerPoint, mapZoom);
        }, 200);
      }
    },
    verifyHash() {
      this.hashFlag = !this.hashFlag;
    },
  },
};
</script>
<style scoped lang="scss">
  .container{
    height: 100%;
  }
.input_box{
  border: 2px solid #409eff;
  color: #F2F6FC;
  height: 40px;
  border-radius: 15px;
  text-align:center;
  vertical-align:middle;
  margin-right: 10px;
}
$color: #2194E0;
@keyframes sheen {
  0% {
    transform: skewY(-45deg) translateX(0);
  }
  100% {
    transform: skewY(-45deg) translateX(12.5em);
  }
}
.button {
  width: 200px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  border: 2px solid $color;
  font-size: 16px;
  display: inline-block;
  border-radius: 15px;
  transition: all 0.2s ease-in-out;
  position: relative;
  overflow: hidden;
  margin-right: 10px;

  &:before {
    content: "";
    background-color: rgba(255, 255, 255, 0.5);
    height: 100%;
    width: 3em;
    display: block;
    position: absolute;
    top: 0;
    left: -4.5em;
    transform: skewX(-45deg) translateX(0);
    transition: none;
  }

  &:hover {
    cursor: pointer;
    background-color: $color;
    color: #fff;
    border-bottom: 4px solid darken($color, 10%);

    &:before {
      transform: skewX(-45deg) translateX(13.5em);
      transition: all 0.5s ease-in-out;
    }

  }
}
input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #afb4db;
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #afb4db;
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #afb4db;
}
input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #afb4db;
}
.chain-block-box {
  position: absolute;
  top: 28px;
  left: 15px;
  z-index: 1099;
  padding: 5px 10px;
  background-color: #1A366A ;
  color: #F2F6FC;
  cursor: default;
}
.hash-value {
  color: #409eff;
  cursor: pointer;
}
.hash-result {
  color: #67c23a;
  cursor: pointer;
}
.container{
  height: 100%;
  width: 100%;
}
  .spread{
    height: 100%;
  }
  .df{
    display: flex;
  }
  @for $i from 1 through 24 {
    .flex-#{$i} {
      flex: 1 round(percentage($i/24));
    }
  }
  .p50{
    padding:0 30px;
    &>div{
      padding: 10px 0;
      border-top: 1px solid #7bbfea;
      display: flex;
      align-items: center;
    }
    &:last-child{
      padding:  0;
      border-bottom: 1px solid #7bbfea;
    }
  }
  $stark-light-blue: #00fffc;
  $stark-med-blue: #00a4a2;
  #fade-box {
    animation: form-entry 2s ease-in-out;
    /*background: #111;*/
    /*background: linear-gradient(#004746, #111);*/
    border: 1px solid $stark-med-blue;
    box-shadow: 0 0 15px #00fffd;
    border-radius: 20px;
    /*display: inline-block;*/
    /*height: 220px;*/
    /*margin: 200px auto 0;*/
    /*position: relative;*/
    z-index: 4;
    /*width: 500px;*/
    transition: 1s all ;

    &:hover {
      border: 1px solid #00fffd;
      box-shadow: 0 0 25px #00fffd;
      transition: 1s all ;
    }
  }
  @keyframes form-entry {
    0% {
      max-height: 80vh;
      width: 0;
      opacity: 0;
      padding: 0;
    }
    20% {
      max-height: 80vh;
      border: 4px solid $stark-med-blue;
      width: 0;
      opacity: 0;
      padding: 0;
    }


    40% {
      width: 0;
      max-height: 80vh;
      border: 4px solid $stark-med-blue;
      opacity: 1 ;
      padding: 0;
    }

    100% {
      max-height: 80vh;
      width: 800px;

    }
  }
  @keyframes input-entry {
    0% {
      opacity: 0;
    }
    90% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
  .record-bg{
    background-image: url("../../../assets/image/cool-background.png");
    overflow: hidden;
    border-radius: 20px;
    background-size: cover;
    z-index: 100;
    /*background-repeat: no-repeat;*/
  }
  .p-50{
    padding: 50px;
  }
  .tf-title{
    display: flex;
    justify-content: center;
    height: 50px;
    color: #EBEFF1;
    font-size: larger;
    font-weight: bold;
  }
</style>
